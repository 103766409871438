.App {
  width: 100%;
  height: 100vh;
  background: #000;
  position: relative;
  overflow: hidden;
  .wrapper {
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
}

// 全局字体样式
* {
  font-size: 18px !important;
  font-family: "楷体", "楷体_GB2312", cursive !important;
}

// 清除 antd tabs 默认样式
.ant-tabs-nav::before {
  border: none !important;
}
.ant-tabs-tab-btn {
  color: #ccc !important;
  text-align: center;
}
.ant-tabs-tab-active {
  .ant-tabs-tab-btn {
    color: #000 !important;
    background-color: #ccc;
    border-radius: 4px;
  }
}
.ant-tabs-tab-disabled .ant-tabs-tab-btn {
  color: #666 !important;
}
.ant-tabs-left > .ant-tabs-content-holder {
  border-left: none !important;
}

// 覆盖 antd menu 默认样式
.ant-menu {
  background-color: transparent;
}
.ant-menu-sub {
  margin-bottom: 48px;
}
.ant-menu-submenu-title,
.ant-menu-item {
  background: linear-gradient(
    to right,
    #000000da,
    #000000da,
    #000000da,
    transparent
  );
  & {
    &:hover {
      background: linear-gradient(
        to right,
        #ccc,
        #ccc,
        #ccc,
        transparent
      ) !important;
      > .ant-menu-title-content {
        color: #000 !important;
      }
    }
  }
}
.ant-menu-item {
  margin-left: 36px;
  width: 80%;
  .ant-menu-title-content {
    font-size: 20px !important;
  }
  &.ant-menu-item-only-child {
    padding-left: 24px !important;
    margin-left: 24px;
  }
}
.ant-menu-title-content {
  color: #ccc !important;
  font-size: 22px !important;
}
.ant-menu-submenu-active,
.ant-menu-submenu-open {
  & {
    .ant-menu-submenu-title {
      background: linear-gradient(
        to right,
        #ccc,
        #ccc,
        #ccc,
        transparent
      ) !important;
    }
    > .ant-menu-submenu-title > .ant-menu-title-content {
      color: #000 !important;
      // background-color: #ccc;
    }
    .ant-menu-item-selected {
      & > .ant-menu-title-content {
        color: #000 !important;
      }
      background: linear-gradient(to right, #ccc, #ccc, #ccc, transparent);
    }
  }
}
.ant-menu-submenu-arrow {
  color: #000 !important;
}
