.wrapper {
  .background-video {
    width: 100%;
    height: 100%;
  }
  .menu {
    width: 300px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -10%);
    :global {
      .ant-menu-item {
        text-align: center;
        margin-bottom: 16px;
        height: 44px;
        line-height: 44px;
        a {
          font-size: 24px !important;
        }
      }
    }
  }
  .pause {
    position: absolute;
    right: 50px;
    top: 50px;
    color: white;
    cursor: pointer;
  }
  .topLink {
    position: absolute;
    left: 50%;
    top: 20px;
    transform: translateX(-50%);
    color: white;
    font-size: 18px;
    text-decoration: none;
    padding: 10px 20px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    transition: all 0.3s ease;

    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
      color: #fff;
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
    }
  }
  .tips {
    position: absolute;
    left: 50%;
    bottom: 120px;
    transform: translate(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: default;
    > img {
      width: 150px;
      margin-bottom: 24px;
    }
    > div {
      animation-duration: 2.5s;
      animation-name: opacityCase;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      color: #ccc;
      font-size: 28px !important;
    }
  }
}

@keyframes opacityCase {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
