.infopage {
  background-image: radial-gradient(
    circle at 0px 200px,
    #222 0%,
    #000 40%,
    #000 100%
  );
  .left-label {
    position: fixed;
    left: -15px;
    top: 20px;
    writing-mode: vertical-rl;
    font-size: 180px !important;
    font-weight: bold;
    text-shadow: 5px 2px 15px #333;
    color: #000000c7;
  }
  .system-tabs {
    margin: 36px !important;
    height: 100%;
    > .ant-tabs-nav {
      > .ant-tabs-nav-wrap > .ant-tabs-nav-list {
        &::before {
          content: "Q";
          background-color: #ccc;
          padding: 0 10px;
          margin: 12px 0;
          margin-left: 48px;
          border-radius: 6px;
        }
        &::after {
          content: "E";
          background-color: #ccc;
          padding: 0 10px;
          margin: 12px 0;
          border-radius: 6px;
        }
      }
      .ant-tabs-tab-btn {
        width: 100px;
      }
      .ant-tabs-tab + .ant-tabs-tab {
        border-left: #666 1px solid !important;
      }
      .ant-tabs-tab {
        margin: 12px 0 !important;
        padding: 0 16px !important;
      }
    }
    .ant-tabs-content {
      height: 100%;
    }
    .ant-tabs-tabpane {
      height: 100%;
    }
  }
}
