.bgmix {
  background-blend-mode: multiply;
  background-image: url("../../public/bgmix.jpg");
}

.hexagon,
.wrapper {
  width: fit-content;
  height: fit-content;
}
.hexagon {
  position: relative;
  .wrapper {
    .top,
    .bottom {
      width: 0;
      height: 0;
    }
  }
  .children {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
