.wrapper {
  height: 100vh;
  background-position: right;
  background-repeat: no-repeat;
  background-size: auto 100%;
  position: relative;
  color: #ccc;
  .declare {
    position: absolute;
    top: 8px;
    left: 50%;
    transform: translateX(-50%);
  }
  .tips {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000a;
    display: flex;
    flex-direction: column;
    align-items: center;
    .time {
      margin-top: 15%;
      padding: 12px 24px;
      background: #000;
      border-radius: 4px;
      text-align: center;
      div + div {
        font-size: 48px !important;
        color: #fff;
      }
    }
    .desc {
      width: 850px;
      text-align: center;
      margin-top: 48px;
      line-height: 24px;
      .yellow {
        color: #a58f5e;
      }
    }
    .confirm {
      margin-top: 80px;
      cursor: pointer;
      > .keyboard {
        background-color: #999;
        margin-right: 16px;
        color: #000;
        padding: 2px 6px;
        border-radius: 2px;
      }
    }
  }
  .left {
    position: absolute;
    top: 25%;
    left: 48px;
    .title {
      height: 200px;
      .label {
        font-size: 32px !important;
        color: #ccc;
      }
      .divider {
        width: 600px;
        height: 1px;
        background-color: #666;
        left: -48px;
        position: absolute;
      }
      .poetry {
        color: #ccc;
        white-space: pre-wrap;
        margin-top: 8px;
      }
      .duration {
        margin-top: 36px;
      }
      .duration,
      .cleared {
        color: #666;
      }
    }
    .menu {
      .item {
        width: 360px;
        height: 48px;
        transition: all 0.5s;
        background-position: 50% 30%;
        background-size: 600px;
        margin-bottom: 8px;
        cursor: pointer;
        &:hover {
          width: 480px;
        }
      }
    }
  }
}

.new-game-modal {
  :global {
    .ant-modal-content {
      padding: 0;
      border-radius: 4px;
      overflow: hidden;
      background-color: #1c1c1c;
    }
    .ant-modal-confirm-content {
      text-align: center;
      padding: 48px;
      color: #ccc;
    }
    .ant-modal-confirm-btns {
      display: flex;
      flex-direction: row-reverse;
      > button {
        flex: 1;
        text-align: center;
        cursor: pointer;
        border-radius: 0;
        border: 0;
        background-color: #333;
        color: #ccc;
        padding: 6px 16px;
        height: auto;
        &:hover {
          border: 0;
          background-color: #ccc;
          color: #333 !important;
        }
      }
      > button + button {
        border-left: none;
        margin-inline-start: 0 !important;
        margin-inline-end: 1px;
      }
    }
  }
}
