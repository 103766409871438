.game-page {
  > * {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .shadow {
    width: 50%;
    background: radial-gradient(circle, #ccc, rgba(16, 16, 16, 0.9), #000);
  }
  .title {
    left: 36px;
    top: 36px;
    display: flex;
    transform: unset;
    align-items: center;
    .tag {
      background-color: antiquewhite;
      border-radius: 4px;
      padding: 2px 12px;
      margin-left: 16px;
    }
    .avatar {
      width: 72px;
    }
    .label {
      color: #ccc;
      margin-left: 16px;
    }
  }
  .bg,
  .shadow {
    height: 100%;
    bottom: 0;
  }
  .progress-wrapper {
    width: 600px;
    bottom: 5%;
    .progress {
      border: 2px solid #ccc;
      width: 100%;
      height: 32px;
      > div {
        background-color: #ccc;
        height: 100%;
        transition: all 0.5s;
      }
      & + div {
        color: #ccc;
        font-size: 32px !important;
        text-align: center;
        margin-top: 24px;
      }
    }
  }
  .countdown {
    bottom: 20%;
    > * {
      text-align: center;
    }
    .tips {
      font-size: 36px !important;
      color: rgb(181, 0, 0);
      font-weight: bold;
      text-shadow: 0 0 16px rgb(181, 0, 0);
      margin-bottom: 8px;
    }
    .time {
      font-size: 65px !important;
      color: rgb(255, 236, 96);
      font-weight: bold;
      text-shadow: 0 0 16px rgb(255, 236, 96);
      margin-bottom: 16px;
    }
    .extra {
      font-size: 28px !important;
      color: #ccc;
      font-weight: bold;
      width: fit-content;
      margin: 0 auto;
      text-shadow: 0 0 8px #000;
    }
  }
}
