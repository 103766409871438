.wukong {
  position: fixed;
  right: 200px;
  bottom: 0;
  height: 100vh;
  pointer-events: none;
}

.user-info {
  position: fixed;
  width: 280px;
  padding-top: 36px;
  right: 16px;
  top: 150px;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  .top {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 2px solid #666;
    position: relative;
    margin-bottom: 24px;
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-radius: 50%;
      background-color: #666;
      width: 60px;
      height: 60px;
      position: absolute;
    }
    .health {
      top: -20px;
      left: 20px;
    }
    .mana {
      top: 50px;
      left: -25px;
      background-color: rgb(48, 68, 100);
    }
    .energy {
      top: 50px;
      left: 65px;
      background-color: rgb(97, 85, 45);
    }
    img {
      width: 16px;
    }
  }
  .divider {
    display: none;
  }
  .tips {
    display: none;
  }
  .base-list {
    > div {
      font-size: 16px !important;
      text-align: center;
      span + span {
        margin-left: 8px;
      }
    }
  }
  .disaster-list {
    display: none;
  }

  &:hover {
    background-image: linear-gradient(to bottom, #333 0%, #000 35%);
    .top {
      background-image: url("../../../../public/background/tongqian.png");
      background-size: cover;
    }
    .divider {
      width: 100%;
      height: 2px;
      display: block;
      background-image: linear-gradient(
        to right,
        transparent,
        #333,
        transparent
      );
    }
    .tips {
      display: block;
      color: #a58f5e;
      font-size: 16px !important;
      margin: 4px 0;
    }
    .base-list,
    .disaster-list {
      width: 100%;
      display: block;
      > div {
        padding: 0 16px;
        display: flex;
        justify-content: space-between;
        background-image: linear-gradient(to top right, #333, transparent);
        margin-bottom: 2px;
      }
    }
  }
}
