.journal {
  margin-left: 120px;
  height: 100%;
  .ant-menu-sub {
    max-height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .top {
    color: #ccc;
    span {
      margin-right: 12px;
      font-size: 20px !important;
    }
  }
  .search {
    width: 180px;
    margin-bottom: 24px;
    background-color: transparent;
    color: #999;
    border-color: #666;
    &:hover,
    &:focus {
      border-color: #ccc;
    }
  }
  .title {
    color: #ccc;
    font-size: 22px !important;
  }
  .content {
    display: flex;
    height: calc(100% - 140px);
  }
  .left {
    height: 100%;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .right {
    color: #ccc;
    width: 300px;
    margin-left: 24px;
    .info {
      height: calc(100% - 35px);
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .label {
      font-size: 22px !important;
      color: #a58f5e;
      border-bottom: 1px solid #ccc;
      margin-bottom: 16px;
    }
    .poetry {
      white-space: pre-wrap;
      margin-bottom: 32px;
      font-size: 16px;
      color: #999;
    }
    .desc {
      white-space: pre-wrap;
      margin-bottom: 48px;
    }
  }
  img {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    max-width: 35%;
    object-fit: cover;
  }
}
